import { BlurView } from "expo-blur";
import { fill, range, sumBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { calcMasterTimeLine } from "../../../Utility/PortfolioRoadmapUtility/utility-portfolio-roadmap";
import { reCalcMasterTimeline } from "../../../Utility/PortfolioRoadmapUtility/utility-portfolio-roadmap";
import PortfolioResults from "./PortfolioResults";
import Purchase from "./Purchase";
import PurchaseDetails from "./PurchaseDetails";
import PurchaseTarget from "./PurchaseTarget";
import PurchaseTargetModal from "./PurchaseTargetEdit";
import RetirementOverview from "./RetirementOverview";
import CashFlow from "./Roadmap/CashFlow";
import EquityDebt from "./Roadmap/EquityDebt";
import Strategy from "./Roadmap/Strategy";
import styles from "./style";
import { setLoading } from "../../../Reducer/portfolio-roadmap";
import { number } from "yup";

const PurchaseOverview = ({ listPurchases }) => {
  const dispatch = useDispatch();
  const is1806 = useMediaQuery({ query: "(min-width: 1806px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const { purchasedProperties, scenarioSelected } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );
  const [visiblePurchaseTargetModal, setVisiblePurchaseTargetModal] =
    useState(false);
  const [purchaseTargetSelected, setPurchaseTargetSelected] = useState(null);
  const [purchaseSelected, setPurchaseSelected] = useState(null);
  const [indexChange, setIndexChange] = useState(0);
  const [index, setIndex] = useState(0);
  const [result, setResult] = useState(null);
  const [lastRetirementOverviewDraff, setLastRetirementOverviewDraff] =
    useState(null);
  const [timeLines, setTimeLines] = useState([]);
  const [disabledClickOutside, setDisabledClickOutside] = useState(false);
  const [nextPurchaseTarget, setNextPurchaseTarget] = useState(null);
  const [dateCurrentTarget, setDateCurrentTarget] = useState(null);
  const [incomeTypeCurrent, setIncomeTypeCurrent] = useState("single");
  const calculateIndexToSkip = useCallback(
    (result) => {
      const indexToSkip = result?.findIndex((item) =>
        moment(item.date).isSameOrAfter(
          moment(scenarioSelected?.purchaseTargets[0]?.purchaseDate)
        )
      );
      return indexToSkip !== -1 ? indexToSkip : result?.length - 1;
    },
    [result]
  );

  const idxSkipped = calculateIndexToSkip(result);

  const renderRetirementSection = () => {
    return (
      <View style={{ zIndex: 1 }}>
        <Text style={styles.title}>Retirement Scenario</Text>
        <View style={styles.content}>
          <View
            style={[
              styles.listPurchase,
              isTabletOrMobile && { flexDirection: "row" },
              { flex: 1 },
              isTabletOrMobile && { gap: 21 },
            ]}
          >
            <RetirementOverview
              result={result}
              lastRetirementOverviewDraff={lastRetirementOverviewDraff}
            />
            <PortfolioResults
              result={result}
              idxSkipped={idxSkipped}
              setLastRetirementOverviewDraff={setLastRetirementOverviewDraff}
            />
          </View>
        </View>
      </View>
    );
  };

  const renderRoadMapSection = () => {
    return (
      <>
        <Strategy
          result={result}
          idxSkipped={idxSkipped}
          onSelect={setPurchaseSelected}
          purchaseSelected={purchaseSelected}
        />
        <View style={[styles.flexRow, { marginTop: 16, height: 276 }]}>
          <CashFlow result={result} idxSkipped={idxSkipped} />
          <EquityDebt result={result} idxSkipped={idxSkipped} />
        </View>
      </>
    );
  };

  useEffect(() => {
    const purchaseTargets = [
      ...listPurchases,
      ...(scenarioSelected?.purchaseTargets?.map((one, index) => ({
        ...one,
        noOfMonth: one.noOfMonth,
        isPurchaseTarget: true,
        date: one.purchaseDate,
      })) || []),
    ];

    if (!purchaseTargets.length) return setResult(null);
    const objInterestRate = scenarioSelected?.purchaseTargets.map(
      (item, index) => {
        return {
          index: index,
          interestRate: Number(item.interestRate),
          noOfMonth: item.noOfMonth,
        };
      }
    );
    const purchaseTargetTrue = purchaseTargets.filter(
      (item) => item.isPurchaseTarget === true
    );
    const purchaseTargetUndefined = purchaseTargets.filter(
      (item) => item.isPurchaseTarget === undefined
    );
    //Split the masterTimeLine logic into separate timeline sections for Purchases and PurchaseTargets
    const masterTimeLinesPurchaseTargetUndefined = purchaseTargetUndefined.map(
      (item, index) => {
        const months =
          sumBy([...purchaseTargets].slice(index), "noOfMonth") + 30 * 12;
        const payload = {
          propertyPrice: item.propertyPrice,
          rentPW: item.rentPW,
          paymentPurchasePerMonth: item.interestPerMonth,
          recommendedBorrowing: item.recommendedBorrowing,
          availableEquity: item.equityFromPurchase,
          growthPercent: item?.isNonTIA ? 8.5 : undefined,
          rentPercent: item?.isNonTIA ? 5.5 : undefined,
          months,
          deposit: item?.deposit,
          interestRate: item?.interestRate,
        };
        return {
          timeline:
            item.isPurchaseTarget && index !== purchaseTargets.length - 1
              ? calcMasterTimeLine(payload).map((one, index) => {
                  return index >= item.noOfMonth - 1
                    ? {
                        ...one,
                        debtRemaining:
                          one.debtRemaining + item.equityFromPurchase,
                      }
                    : { ...one };
                })
              : calcMasterTimeLine(payload),
          noOfMonth: item.noOfMonth,
          isNonTIA: item.isNonTIA,
          isPurchaseTarget: item?.isPurchaseTarget,
        };
      }
    );
    let masterTimeLinesPurchaseTargetTrue = [];
    purchaseTargetTrue.forEach((item, index) => {
      const months =
        sumBy(
          [...purchaseTargets].slice(index + purchaseTargetUndefined.length),
          "noOfMonth"
        ) +
        30 * 12;
      const payload = {
        propertyPrice: item.propertyPrice,
        rentPW: item.rentPW,
        paymentPurchasePerMonth: item.interestPerMonth,
        debtOnProperty: item.loanAmount,
        availableEquity: purchaseTargetTrue[index + 1]?.equityFromPurchase,
        growthPercent: item?.isNonTIA ? 8.5 : undefined,
        rentPercent: item?.isNonTIA ? 5.5 : undefined,
        deposit: item?.deposit,
        interestRate: item?.interestRate,
        months,
        noOfMonth: purchaseTargetTrue[index + 1]?.noOfMonth,
      };
      let timeline =
        item.isPurchaseTarget && index !== purchaseTargets.length - 1
          ? calcMasterTimeLine(payload).map((one, index) => {
              return index >= item.noOfMonth - 1
                ? {
                    ...one,
                    debtRemaining: one.debtRemaining,
                  }
                : { ...one };
            })
          : calcMasterTimeLine(payload);
      objInterestRate.forEach((i) => {
        if (i.index >= index) {
          const coincideMonth = i.index - index > 1 ? i.index - index - 1 : 0;
          const indexMonth =
            objInterestRate
              .slice(index + 1, i.index + 1)
              .reduce((sum, currentItem) => sum + currentItem.noOfMonth, 0) -
            coincideMonth -
            1;
          timeline = reCalcMasterTimeline({
            timeLine: timeline,
            rate: Number(item.interestRate),
            dataChange: {
              indexMonth: indexMonth,
              interestRate: i.interestRate / 100,
            },
          });
        }
      });
      masterTimeLinesPurchaseTargetTrue.push({
        timeline: timeline,
        noOfMonth: item.noOfMonth,
        isNonTIA: item.isNonTIA,
        isPurchaseTarget: item?.isPurchaseTarget,
      });
    });
    const masterTimeLines = [
      ...masterTimeLinesPurchaseTargetUndefined,
      ...masterTimeLinesPurchaseTargetTrue,
    ];

    let months = 0;
    const newMasterTimeLine = masterTimeLines.map((item, index) => {
      const currentTimeLine = masterTimeLines[index];
      const dateBought = moment(purchaseTargets[index]?.date).format("MM/YY");
      if (index) months += currentTimeLine ? currentTimeLine.noOfMonth - 1 : 0;
      else months += currentTimeLine ? currentTimeLine.noOfMonth : 0;
      return {
        timeLine: [
          ...fill(Array(months), {
            value: 0,
            grossCF: 0,
            netCF: 0,
            debtRemaining: 0,
            equity: 0,
            value: 0,
            rentPerMonth: 0,
            grossYield: 0,
            netYield: 0,
            interestPerMonth: 0,
          }),
          ...item.timeline,
        ],
        noOfMonth: item.noOfMonth,
        dateBought,
      };
    });

    const totalMonths = range(newMasterTimeLine[0].timeLine?.length).length;
    let data = [];
    for (let i = 0; i <= totalMonths - 1; i++) {
      const date = moment(purchaseTargets[0].date).add(i, "month");
      const payload = {
        grossCFValue: 0,
        netCFValue: 0,
        debtRemainingValue: 0,
        equityValue: 0,
        isBought: newMasterTimeLine.some((one) => {
          const dateBoughtStr = moment(one?.dateBought, "MM/YY").format(
            "MM/YY"
          );
          const dateStr = date.format("MM/YY");
          // const oneDateBought = moment(one.dateBought, "MM/YY");
          // console.log("date", date);
          // const newDate = moment(date, "MM/YY");
          // console.log("newDate", newDate);
          // const isSame =
          //   oneDateBought.isSame(newDate, "month") &&
          //   oneDateBought.isSame(newDate, "year");
          return dateBoughtStr === dateStr;
        }),
        value: 0,
        date,
        rentPerMonth: 0,
        grossYield: 0,
        netYield: 0,
        interestPerMonth: 0,
        equityPull: 0,
      };
      newMasterTimeLine.forEach((one) => {
        const equityPullInterest =
          one?.timeLine?.[i]?.equityPullInterestPm ?? 0;
        const equityPull = one?.timeLine?.[i]?.equityPull ?? 0;
        payload.grossCFValue += one?.timeLine?.[i]?.grossCF ?? 0;
        payload.netCFValue += one?.timeLine?.[i]?.netCF ?? 0;
        payload.debtRemainingValue +=
          one?.timeLine?.[i]?.debtRemaining - equityPull ?? 0;
        payload.equityValue += one?.timeLine?.[i]?.newEquity ?? 0;
        payload.value += one?.timeLine?.[i]?.value ?? 0;
        payload.rentPerMonth += one?.timeLine?.[i]?.rentPerMonth ?? 0;
        payload.grossYield += one?.timeLine?.[i]?.grossYield ?? 0;
        payload.netYield += one?.timeLine?.[i]?.netYield ?? 0;
        payload.interestPerMonth +=
          one?.timeLine?.[i]?.interestPerMonth + equityPullInterest ?? 0;
      });

      data.push(payload);
    }
    setTimeLines(data);
  }, [scenarioSelected?.purchaseTargets, listPurchases]);

  useEffect(() => {
    dispatch(setLoading(true));
    const yearTo =
      scenarioSelected?.yearsOfResult &&
      moment()
        .year(scenarioSelected.yearsOfResult)
        .endOf("year")
        .format("MM/YY");
    const idx = timeLines.findIndex((item) => {
      return item.date.format("MM/YY") === yearTo;
    });
    const newData = [...timeLines];
    newData.splice(idx + 1);
    setTimeout(() => {
      setResult(newData);
      dispatch(setLoading(false));
    }, [1000]);
  }, [timeLines, scenarioSelected?.yearsOfResult]);

  useEffect(() => {
    setDisabledClickOutside(visiblePurchaseTargetModal);
  }, [visiblePurchaseTargetModal]);

  const purchaseTargets = scenarioSelected?.purchaseTargets?.filter((item) => {
    return (
      Number(moment(item.purchaseDate).format("YYYY")) <=
      scenarioSelected?.yearsOfResult
    );
  });

  const purchased = purchasedProperties?.sort(
    (a, b) =>
      moment(Number(a["Purchase date "])) - moment(Number(b["Purchase date "]))
  );

  return (
    <>
      <View
        style={[styles.container, isTabletOrMobile && { width: "fit-content" }]}
      >
        <View
          style={[{ zIndex: 2 }, isTabletOrMobile && { height: "fit-content" }]}
        >
          <Text style={styles.title}>Purchase Overview</Text>
          <View style={[styles.content, { minHeight: 592 }]}>
            <ScrollView
              contentContainerStyle={styles.listPurchase}
              showsVerticalScrollIndicator={false}
            >
              {!!purchased?.length &&
                purchased.map((item) => (
                  <Purchase
                    key={item.id}
                    properties={item}
                    onSelect={setPurchaseSelected}
                    purchaseSelected={purchaseSelected?.data}
                  />
                ))}
              {!!purchaseTargets?.length &&
                purchaseTargets.map((item, index) => (
                  <PurchaseTarget
                    setVisibleModalEditTarget={() => {
                      setPurchaseTargetSelected(item);
                      setVisiblePurchaseTargetModal(true);
                      setIndex(index);
                    }}
                    setVisibleModalAddTarget={() => {
                      setDateCurrentTarget(item.purchaseDate);
                      setIndexChange(index + 1);
                      setVisiblePurchaseTargetModal(true);
                      setIndex(index);
                      setNextPurchaseTarget(purchaseTargets?.[index + 1]);
                      setIncomeTypeCurrent(item.incomeType);
                    }}
                    disabledClickOutside={disabledClickOutside}
                    purchaseTargets={scenarioSelected.purchaseTargets}
                    purchaseSelected={purchaseSelected?.data}
                    onSelect={setPurchaseSelected}
                    properties={item}
                    index={index}
                    key={item.id}
                    type="HOUSE"
                  />
                ))}
            </ScrollView>
          </View>
        </View>
        <View
          style={[
            { zIndex: 1, minWidth: 800 },
            is1806 || isTabletOrMobile ? { flex: 1 } : { flexGrow: 1 },
          ]}
        >
          <Text style={styles.title}>Roadmap</Text>
          <View style={styles.content}>
            {isTabletOrMobile ? (
              <>
                {purchaseSelected?.type === "purchase-details" ? (
                  <PurchaseDetails
                    properties={purchaseSelected?.data}
                    onSelect={setPurchaseSelected}
                    purchaseSelected={purchaseSelected?.data}
                  />
                ) : (
                  renderRoadMapSection()
                )}
              </>
            ) : (
              <>
                {purchaseSelected?.type === "purchase-details" && (
                  <BlurView tint="light" intensity={40} style={styles.blurView}>
                    <PurchaseDetails
                      properties={purchaseSelected?.data}
                      onSelect={setPurchaseSelected}
                      purchaseSelected={purchaseSelected?.data}
                    />
                  </BlurView>
                )}
                {renderRoadMapSection()}
              </>
            )}
            {isTabletOrMobile && (
              <View style={{ marginTop: 20 }}>{renderRetirementSection()}</View>
            )}
          </View>
        </View>
        {!isTabletOrMobile && renderRetirementSection()}
      </View>
      {visiblePurchaseTargetModal && (
        <PurchaseTargetModal
          properties={purchaseTargetSelected}
          onClose={() => {
            setVisiblePurchaseTargetModal(false);
            setPurchaseTargetSelected(null);
            setNextPurchaseTarget(null);
            setDateCurrentTarget(null);
          }}
          nextPurchaseTarget={nextPurchaseTarget}
          indexChange={indexChange}
          index={index}
          dateCurrentTarget={dateCurrentTarget}
          typeIncomeCurrentTarget={incomeTypeCurrent}
        />
      )}
    </>
  );
};

export default PurchaseOverview;
