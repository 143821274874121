import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { memo } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";

import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";
import { formatDollar, formatDollarUnit } from "../Utility/common";

const SliderBase = ({
  styleContainer,
  styleLabel,
  styleTippy,
  styleLimit,
  styleSlider,
  label,
  value,
  minimumValue,
  maximumValue,
  reverse,
  onChange,
  landmark,
  handleToMark,
}) => {
  const markerPosition = (landmark / maximumValue) * 100;

  return (
    <View style={[styles.container, styleContainer]}>
      <View style={[styles.boxLabel, styleLabel]}>
        <Text style={styles.label}>{label}</Text>
        <Text style={[styles.tippyBox, styleTippy]}>
          {formatDollar(Math.round(value))}
        </Text>
      </View>
      <View style={{ width: "100%" }}>
        <View style={{ position: "relative" }}>
          <Slider
            min={minimumValue}
            max={maximumValue}
            step={10}
            reverse={reverse}
            style={{ padding: 0, ...styleSlider }}
            value={value}
            onChange={onChange}
            trackStyle={[
              { backgroundColor: styleTippy.backgroundColor },
              { backgroundColor: styleTippy.backgroundColor },
            ]}
            handleStyle={[
              {
                zIndex: 2,
                height: 14,
                width: 14,
                paddingHorizontal: 6,
                borderRadius: "50%",
                borderWidth: 1,
                borderColor: MAIN_COLOR.BACKGROUND_COLOR,
                backgroundColor: styleTippy.backgroundColor,
                boxShadow: "1px 1px 3px rgba(158, 158, 158, 0.15)",
              },
            ]}
          />
          <TouchableOpacity
            onPress={handleToMark}
            style={[
              styles.mark,

              reverse
                ? {
                    right: markerPosition < 0 ? 0 : `${markerPosition}%`,
                    transform: [{ translateX: "50%" }],
                  }
                : {
                    left: markerPosition < 0 ? 0 : `${markerPosition}%`,
                    transform: [{ translateX: "-50%" }],
                  },
            ]}
          ></TouchableOpacity>
        </View>
        <View style={[styles.rowCenterBetween, styleLimit]}>
          <Text style={styles.textLimit}>{formatDollarUnit(minimumValue)}</Text>
          <Text style={styles.textLimit}>{formatDollarUnit(maximumValue)}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
  },
  boxLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 4,
  },
  tippyBox: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 5,
    fontSize: 12,
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
  },

  rowCenterBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },

  textLimit: {
    fontSize: 10,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },

  label: {
    fontSize: 10,
    fontWeight: 500,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },

  thumb: {
    height: 14,
    width: 14,
    paddingHorizontal: 6,
    borderRadius: "50%",
    borderWidth: 1,
    borderColor: MAIN_COLOR.BACKGROUND_COLOR,
    boxShadow: "1px 1px 3px rgba(158, 158, 158, 0.15)",
  },

  mark: {
    position: "absolute",
    top: 7,
    width: 10,
    height: 10,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderWidth: 1,
    borderColor: MAIN_COLOR.G600,
    borderRadius: "50%",
    zIndex: 1,
  },
});

export default memo(SliderBase);
