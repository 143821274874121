import React, { useEffect, useState } from "react";
import {
  Image,
  Linking,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";
import { format, getTime } from "../../../Zoho/Tasks";
import { MAIN_COLOR } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import moment from "moment";
import styles from "../style";
import PropertyContacts from "../PropertyContacts";
import { getListMarketHistory } from "../../../Clickup/api";
import { formatDollar } from "../../../Utility/common";

const PortfolioItem = ({ item, index }) => {
  const [showContacts, setShowContacts] = useState(false);
  const [potentialValue, setPotentialValue] = useState(0);
  const [loadingValue, setLoadingValue] = useState(false);

  useEffect(() => {
    setShowContacts(false);
    const fetchMarketHistory = async () => {
      const payload = {
        state: item.state,
        property_suburb: item.core_suburb,
        no_bed_room: item.beds * 1,
        purchase_date: moment(item.purchase_date).format("DD-MM-YYYY"),
        purchase_price: item.price * 1,
        id: item.id,
      };

      setLoadingValue(true);
      const response = await getListMarketHistory(
        payload,
        `is_portfolio=${true}`
      );
      setLoadingValue(false);
      console.log({ response });

      if (response.status === 200)
        setPotentialValue(response.data.sales_median_12mths);
      else setPotentialValue(0);
    };
    fetchMarketHistory();

    return () => setPotentialValue(0);
  }, [item]);

  return (
    <View
      activeOpacity={0.8}
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: 20,
        padding: "5%",
        marginBottom: 10,
      }}
    >
      {showContacts ? (
        <PropertyContacts
          portfolio={item}
          goBack={() => setShowContacts(false)}
        />
      ) : (
        <>
          <Text
            style={{
              fontSize: 16,
              color: MAIN_COLOR.G800,
              fontFamily: MAIN_FONT.semi,
              fontWeight: "500",
              marginBottom: 5,
            }}
          >
            {item.address ? item.address : "Property Address"},{" "}
            {item.Suburb ? item.Suburb : "Suburb"}
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G700,
                fontFamily: MAIN_FONT.regular,
                marginBottom: 5,
              }}
            >
              Last Update:
            </Text>

            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G800,
                fontFamily: MAIN_FONT.regular,
                textAlign: "left",
              }}
            >
              {getTime(item.date_updated * 1)} -{" "}
              {moment(item.date_updated * 1).format("DD/MM/YYYY")}
            </Text>
          </View>

          <View
            style={{
              height: 1,
              backgroundColor: "#E7ECF2",
              alignSelf: "center",
              width: "100%",
              marginTop: 30,
              marginBottom: 20,
            }}
          />

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G700,
                fontFamily: MAIN_FONT.regular,
                marginBottom: 5,
              }}
            >
              Purchase Price
            </Text>

            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G800,
                fontFamily: MAIN_FONT.semi,
                textAlign: "right",
              }}
            >
              ${format(item.price)}
            </Text>
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G700,
                fontFamily: MAIN_FONT.regular,
                marginBottom: 5,
              }}
            >
              Growth Since Purchase
            </Text>
            <Text
              style={{
                fontSize: 18,
                color: MAIN_COLOR.PRIMARY_COLOR,
                fontFamily: MAIN_FONT.semi,
                textAlign: "right",
              }}
            >
              {!loadingValue ? (
                `${
                  potentialValue
                    ? (
                        ((potentialValue - Number(item.price)) * 100) /
                        Number(item.price)
                      ).toFixed(2)
                    : 0
                }%`
              ) : (
                <ActivityIndicator size="small" color={MAIN_COLOR.yellow} />
              )}
            </Text>
          </View>

          <View
            style={{
              height: 1,
              backgroundColor: "#E7ECF2",
              alignSelf: "center",
              width: "100%",
              marginTop: 30,
              marginBottom: 20,
            }}
          />

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G800,
                fontFamily: MAIN_FONT.semi,
                marginBottom: 5,
              }}
            >
              Current Potential Value
            </Text>
            <Text
              style={{
                fontSize: 20,
                color: MAIN_COLOR.PRIMARY_COLOR,
                fontFamily: MAIN_FONT.semi,
                textAlign: "right",
              }}
            >
              {!loadingValue ? (
                formatDollar(potentialValue)
              ) : (
                <ActivityIndicator size="small" color={MAIN_COLOR.yellow} />
              )}
            </Text>
          </View>

          <View
            style={{
              height: 1,
              backgroundColor: "#E7ECF2",
              alignSelf: "center",
              width: "100%",
              marginTop: 30,
              marginBottom: 20,
            }}
          />

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{
                fontSize: 14,
                color: MAIN_COLOR.G600,
                fontFamily: MAIN_FONT.regular,
              }}
            >
              Comparable Property
            </Text>
            <TouchableOpacity onPress={() => Linking.openURL(item.url)}>
              <Text
                style={[
                  styles.priceTitle,
                  {
                    textAlign: "center",
                    fontFamily: MAIN_FONT.bold,
                    fontSize: 14,
                    color: MAIN_COLOR.PRIMARY_COLOR,
                    textDecorationLine: "underline",
                  },
                ]}
              >
                View
              </Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 10,
            }}
          >
            <Image
              source={{ uri: item.image }}
              resizeMode="cover"
              style={{
                height: "100%",
                resizeMode: "cover",
                width: "25%",
                height: 100,
                borderRadius: 10,
              }}
            />
            <View style={{ width: "75%", paddingLeft: "5%" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    color: MAIN_COLOR.G700,
                    fontFamily: MAIN_FONT.regular,
                  }}
                >
                  Purchased:
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    color: MAIN_COLOR.G700,
                    fontFamily: MAIN_FONT.regular,
                    textAlign: "right",
                  }}
                >
                  {item.purchase_date}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    color: MAIN_COLOR.G700,
                    fontFamily: MAIN_FONT.regular,
                  }}
                >
                  Sold Price:
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    color: MAIN_COLOR.G700,
                    fontFamily: MAIN_FONT.regular,
                    textAlign: "right",
                  }}
                >
                  {item.sold_price ? "$" + format(item.sold_price) : 0}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 17,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={styles.icon}
                    resizeMode="contain"
                    source={require("../../../assets/bed.png")}
                  />
                  <Text style={styles.iconTitle}>{item.beds}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 17,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={styles.icon}
                    resizeMode="contain"
                    source={require("../../../assets/bath.png")}
                  />
                  <Text style={styles.iconTitle}>{item.baths}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 17,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={styles.icon}
                    resizeMode="contain"
                    source={require("../../../assets/car.png")}
                  />
                  <Text style={styles.iconTitle}>{item.cars}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 17,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.iconTitle}>{item.area}m2</Text>
                </View>
              </View>
            </View>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: item.purchase_by
                ? MAIN_COLOR.BORDER_COLOR
                : MAIN_COLOR.PRIMARY_COLOR,
              padding: 8,
              borderRadius: 8,
              marginTop: 12,
            }}
            disabled={item.purchase_by}
            onPress={() => setShowContacts(true)}
          >
            <Text
              style={{
                fontFamily: MAIN_FONT.regular,
                fontSize: 14,
                textAlign: "center",
              }}
            >
              Property Contacts
            </Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
};

export default PortfolioItem;
